import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';


const Class7Fieldtrip2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class7Fieldtrip/8.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 2,
            cols: 2,
        },
        {
            src: p4,
            source: p4,
            rows: 2,
            cols: 2,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 2,
            cols: 2,
        },
       
        {
            src: p8,
            source: p8,
            rows: 2,
            cols: 2,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                class 7 to Suggee Resort
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 7   DATE OF EVENT: 30 th September, 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur organised a field trip for students of class 7 to Suggee Resort. The
                                    students were greeted by the crisp autumn air and vibrant fall colours. The resort staff welcomed and
                                    outlined the itinerary, igniting excitement among the students.
                                    <br />
                                    Throughout the day, the students were engaged in several activities like Land Zorbing, Zip Lining, Wall
                                    Climbing, Archery, Foosball and High Rope Course. The level of engagement was remarkable. Sumptuous
                                    food was provided by the resort to keep the children energised and on their toes all through the day.
                                    <br />
                                    The visit to Suggee Resort was a valuable experience that underscored the significance of health and wellness
                                    in our lives. By combining education with leisure, students were filled with lasting memories.
                                    <br />

                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Making memories one field trip at a time!”
                                <br />
                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Class7Fieldtrip2024;